.viewer-recommends-container {
  margin-top: 10px;
  margin-bottom: 90px;
}

.viewer-recommends-similar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;

  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.viewer-recommends-similar-item-container {
  width: 250px;
  min-width: 250px;
  height: 130px;
  padding: 10px 10px;
  margin-right: 10px;
  background: #ffffff;
  /* 시그니처컬러 */

  border: 1px solid #2d9cdb;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

.viewer-recommends-similar-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.viewer-recommends-similar-item-header-title {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */

  letter-spacing: -0.03em;

  /* 시그니처컬러 */

  color: #2d9cdb;
  margin-right: 5px;
}

.viewer-recommends-similar-item-title-icon-container {
  margin-right: 5px;
  min-width: 30px;
  height: 17px;
  position: relative;
}

.viewer-recommends-similar-item-title-inner-icon {
  position: absolute;
  left: 0px;
  top: -5px;
  min-width: 23px;
  height: 30px;
}

.viewer-recommends-similar-item-text {
  height: 46px;
  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -0.04em;

  color: #171717;
}

.viewer-recommends-item-info {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 100%;
  /* identical to box height, or 10px */

  letter-spacing: -0.04em;

  /* 프로필날짜조회수 */

  color: #888888;
  margin-right: 20px;
}

.viewer-recommends-prev-next-container {
  width: 100%;
  padding: 10px 8px;
}

.viewer-recommends-prev-next-item-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: #f3fbff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  padding: 7px 5px;
  margin-bottom: 10px;
}

.viewer-recommends-prev-next-item-title-icon-container {
  padding: 0px 5px;
  min-width: 40px;
}

.viewer-recommends-prev-next-item-title-inner-icon {
  min-width: 23px;
  height: 30px;
}

.viewer-recommends-prev-next-item-detail-container {
  flex-grow: 1;
}

.viewer-recommends-prev-next-item-text {
  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */

  letter-spacing: -0.04em;

  color: #171717;
}

.viewer-recommends-prev-next-item-type {
  min-width: 50px;

  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */

  letter-spacing: -0.03em;
  text-align: center;
  /* 시그니처컬러 */

  color: #2d9cdb;
}

.viewer-recommends-option-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 8px;
  padding-bottom: 8px;
}
