.ion-color-html-composer-component-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 53px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 53px;

  font-size: 16px;
  line-height: normal;
}

.html-composer-component-content {
  height: 100%;
  /* overflow-y: hidden; */
}
.html-composer-component-mini {
  width: 100%;
}

.html-composer-component-container {
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  /* display: flex; */
  /* flex-direction: column; */
  padding-left: 17px;
  padding-right: 17px;
  /* padding-bottom: 40px; */
}

.html-composer-component-button {
  font-size: 16px;
  line-height: normal;
  color: #2d9cdb;
  font-weight: bold;
  padding-right: 10px;
}

.ion-color-html-composer-component-show-parent {
  background: #fafafa;
  /* 시그니처컬러 */

  border: 1px solid #2d9cdb;

  --box-shadow: none;

  color: #2d9cdb;
  --ion-color-base: #2d9cdb;
  margin-right: 20px !important;
}

.html-composer-component-show-parent {
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  letter-spacing: -0.02em;
  padding: 0px 10px;
}

.html-composer-component-button-disabled {
  font-size: 16px;
  line-height: normal;
  color: #c4c4c4;
  padding-right: 10px;
}

.ion-color-html-composer-component-checkbox {
  margin-right: 5px;
  --background-checked: #2d9cdb;
  --border-color-checked: #2d9cdb;
  --border-color: #c4c4c4;
  --checkmark-color: #fff;
}

.html-composer-component-profile-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.html-composer-component-title-container {
  width: calc(100% - 14px);
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 0.5px solid #ececec;
}

.html-composer-component-title-icon {
  height: 17px;
  width: 17px;
  margin-right: 10px;
  margin-top: 6px;
}

.html-composer-component-title-input {
  z-index: 2;
  width: 100%;
  font-weight: 800;

  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.04em;
  color: #171717;
  --padding-start: 0px;
  --padding-end: 0px;
  outline-style: none;
  border: none;
}

.html-composer-component-title-input::placeholder {
  color: #a1a0a5;
}
.html-composer-component-title-input::-webkit-input-placeholder {
  color: #a1a0a5;
}

.html-composer-component-title-input-container {
  flex-grow: 1;
  position: relative;
  align-items: center;
}

.html-composer-component-hidden {
  display: none;
}

.html-composer-component-title-placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-left: 8px;
  font-weight: 800;

  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.04em;

  color: #c0c0c0;
  text-align: left;
}

.html-composer-component-title-placeholder-sub {
  font-size: 15px;
}

.html-composer-component-body-container {
  position: relative;
  width: calc(100% - 14px);
  height: calc(100% - 167px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin-left: 7px;
  margin-right: 7px;
  padding-bottom: 10px;
}

.html-composer-component-content-placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  font-size: 14px;
  line-height: 22px;

  color: #ababab;
}

.html-composer-component-body {
  position: relative;
  background: #0000;
  z-index: 2;
  height: auto;
  width: 100%;
  border: 1px;

  font-size: 14px;
  line-height: 22px;
  padding: 0px;
  color: #171717;
  outline-style: none;
}
.html-composer-component-images-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.html-composer-component-images-preview {
  width: 72px;
  height: 72px;
  border-radius: 13px;
  border-width: 0px;
  object-fit: cover;
  margin-right: 8px;
}

.html-composer-component-files-container {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: calc(100% - 14px);
  margin-left: 7px;
  margin-right: 7px;
}

.html-composer-component-toolbar-button-container {
  /* overflow: hidden; */
  display: inline-block;
  position: relative;
  height: 24px;
  width: 28px;
  margin: 0px;
  padding: 3px 5px;
}

.ion-color-html-composer-component-toolbar-button {
  --ion-color-base: #494949;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --padding-start: 0px;
  --padding-end: 0px;
  height: 24px;
  width: 28px;
  margin: 0px;
}

.html-composer-component-toolbar-button-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 28px;
  height: 24px;
}

.html-composer-component-toolbar-button-icon {
  width: 18px;
  height: 18px;
}

.ql-toolbar.ql-snow {
  color: #494949;
  background: #fafafa;
  border: 1px solid #f5f5f6;
  border-radius: 0px;
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 12px;
}

.ql-container.ql-snow {
  border: none !important;
  height: calc(100% - 102px);
}

.ql-editor {
  -webkit-user-select: text;
  max-height: none !important;
  padding: 0px !important;

  font-size: 14px !important;
  line-height: 22px !important;
  color: #171717;
}

.ql-editor.ql-blank::before {
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 22px !important;

  color: #ababab;
}

.ql-mention-list-item.selected {
  background-color: #dae8ef !important;
}

.ql-mention-list-item {
  padding: 0 !important;
  height: 40px;
}

.mention {
  border-radius: 6px !important;
  padding: 2px !important;
  background-color: #dae8ef !important;
  font-weight: bold;
}

.html-composer-component-search-medicine-item-container {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.html-composer-component-search-medicine-item-type {
  color: #2d9cdb;
  display: flex;
  padding-top: 3px;
  flex-direction: column;
  align-items: center;
  min-width: 35px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #2d9cdb;
  font-weight: 800;

  font-size: 10px;
  line-height: 14px;
  margin-right: 10px;
  letter-spacing: -0.04em;
}

.html-composer-component-search-medicine-item-info {
  flex-grow: 1;
}

.html-composer-component-search-medicine-item-name {
  font-weight: 800;

  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.04em;
  color: #171717;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.html-composer-component-search-medicine-item-description {
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.04em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2d9cdb;
}

.html-composer-component-component {
  background: #2d9cdb80;
  border-radius: 4px;
  padding: 0px 4px;
}

.html-composer-component-anonymous-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.html-composer-component-anonymous-toggle {
  zoom: 0.6;
  --background-checked: #2d9cdb;
  --background: #a1a0a5;
}

.html-composer-component-anonymous-button {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #2d9cdb;
}

.html-composer-component-anonymous-button-disabled {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #a1a0a5;
}
