@import './../../_design/color.scss';

.tab-bar-container-1 {
    width: 100%;
    max-width: 100%;
    padding: 0px 24px;
    height: 53px;
    min-height: 53px;
    background: $White;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
    position: relative;
    border-bottom: 1px solid $Gray8 !important;

    .tab-bar-items-container{
        height: 100%;
        white-space: nowrap;
        display: inline-block;

        .tab-bar-item-container{
            display: inline-block;
            position: relative;
            height: 100%;
            background: $White;
        }
    
        .tab-bar-item-container-inner {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    
        .tab-bar-item-container-fit{
            margin-right: 20px;
        }
    
        .tab-bar-item{
            padding: 11px 0px;
            position: relative;
            height: 51px;
            overflow: hidden;
            color: $Gray5;
            border-bottom: none;
            background: $White;
        }
    
        .tab-bar-item-selected { 
            color: $Secondary !important;
            border-bottom: 2px solid $Secondary !important;
        }
    }
}

.tab-bar-container-2 {
    width: 100%;
    max-width: 100%;
    padding: 0px 24px;
    height: 32px;
    background: $White;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
    overflow-y: hidden;
    position: relative;

    .tab-bar-items-container{
        height: 100%;
        white-space: nowrap;

        .tab-bar-item-container{
            display: inline-block;
            position: relative;
            height: 100%;
            background: $White;
        }

        .tab-bar-item-container-inner {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .tab-bar-item-container-fit{
            margin-right: 16px;
        }

        .tab-bar-item{
            padding: 6px 12px;
            position: relative;
            height: 100%;
            overflow: hidden;
            color: $Gray3;
            background-color: $Gray8;
            border-radius: 12px;
        }

        .tab-bar-item-selected { 
            color: $White !important;
            background-color: $Secondary !important;
        }
    }
}

.tab-bar-container-3 {
    width: 100%;
    max-width: 100%;
    padding: 0px 24px;
    height: 46px;
    background: $White;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
    white-space: nowrap;
    position: relative;

    .tab-bar-items-container{
        height: 100%;
        white-space: nowrap;

        .tab-bar-item-container{
            display: inline-block;
            position: relative;
            height: 100%;
            background: $White;
        }

        .tab-bar-item-container-inner {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .tab-bar-item-container-fit{
            margin-right: 16px;
        }

        .tab-bar-item{
            padding: 12px 0px;
            position: relative;
            height: 100%;
            overflow: hidden;
            color: $Gray4;
            border-bottom: none;
            background: $White;
        }

        .tab-bar-item-selected { 
            color: $Primary !important;
            border-bottom: 2px solid $Primary !important;
        }
    }
}
