.myscrap-content {
  width: 100%;
  --background: #dae8ef;
  --overflow: hidden;
}

.myscrap-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  align-items: center;
}

.myscrap-header {
  height: 43px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.ion-color-myscrap-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 43px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 43px;

  font-size: 16px;
  line-height: normal;
  align-items: center;
  color: #a1a0a5;
}

.ion-color-myscrap-segment {
  margin-top: 15px;
  width: calc(100% - 16px);
  margin-left: 8px;
  margin-right: 8px;
  height: 41px;
  border-radius: 13px 13px 0px 0px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
  background-color: #a1a0a5;
  justify-content: left;
  --ion-color-base: #0000;
  /* margin-bottom: -16px; */
}

.myscrap-segment-button {
  height: 41px !important;
  min-height: 41px;
  min-width: 80px;
  width: 100px;
  max-width: 120px;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #ffffff !important;
  border-radius: 13px 13px 0px 0px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
  background: #a1a0a5 !important;
}

.myscrap-segment-button.segment-button-checked {
  background: #2d9cdb !important;

  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
}

.myscrap-segment-button-text {
  width: 100%;
  height: 41px;
  padding-top: 7px;
}

.myscrap-segment-title {
  position: relative;
  border-radius: 13px 13px 0px 0px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
  background: #fff;
  width: calc(100% - 16px);
  margin-left: 8px;
  margin-right: 8px;
  height: 14px;
  margin-top: -13px;
  padding-top: 0px;
  padding-left: 16px;
}

.myscrap-segment-title-highlight {
  font-weight: bold;
}

.myscrap-list-container-with-foot {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  height: calc(100vh - 145px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.myscrap-list-container {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  height: calc(100vh - 98px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}
