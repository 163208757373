@import './../../_design/color.scss';

.contract-update-item-all{
    padding: 17.5px 12px;
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    border-radius: 12px;
}

.contract-update-item-all.checked{
    background-color: $LightPrimary;
}
.contract-update-item-all.unchecked{
    background-color: $Gray8;
}

.contract-update-item { 
    padding: 9.5px 12px;
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
}

.contract-update-comment{
    padding: 17.5px 12px;
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    border-radius: 12px;
    background-color: $Gray8;
}