.chat-header-title {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0px;
  top: 0px;
  padding-top: 12px;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #171717;
}

.chat-header-call-button {
  background: #fff;
  border-radius: 5px;
  padding: 6px 12px;

  font-style: normal;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 100%;
  color: #2667eb !important;
  --ion-color-base: #2667eb !important;
  border: 1px solid #2667eb;
  height: 24px !important;
}

.chat-header-info-container {
  width: 100%;
  height: 64px;
  padding: 5px 10px;
  border-bottom: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-header-info-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 80px;
  min-width: 80px;
  height: 54px;
  background: #dae8ef;
  border-radius: 15px;
  margin-right: 20px;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 120%;
  padding: 0px 5px;
}

.chat-header-info-title {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;

  color: #000000;
  margin-bottom: 6px;
}

.chat-header-info-price {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 135%;
  /* or 20px */

  color: #000000;
}

.chat-list-item-container {
  width: 100%;
  padding: 6px 10px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e2e2e2;
  align-items: center;
}

.chat-list-item-profile {
  width: 56px;
  height: 56px;
  border-radius: 40px;
  margin-right: 16px;
}

.chat-list-item-type {
  padding: 4px;
  background: #dae8ef;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-right: 10px;
  margin-bottom: 2px;
}

.chat-list-item-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  letter-spacing: -0.2px;

  color: #171717;
}

.chat-list-item-time {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  color: #888;
}

.chat-list-item-body {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  flex-grow: 1;

  color: #9e9e9e;
}

.chat-list-item-unread-count {
  height: 16px;
  min-width: 16px;
  padding: 3px 4px;
  border-radius: 8px;

  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 100%;
  /* or 10px */

  text-align: center;

  /* 화이트 */
  background: #fd6128;
  color: #ffffff;
}
