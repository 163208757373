.boardcontentlist-notice-item-container {
  background: #ffffff;

  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  border: none;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 8px;
  margin-left: 8px;
  width: calc(100% - 16px);
  padding-left: 16px;
}

.boardcontentlist-notice-item-head-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.boardcontentlist-notice-item-head {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
}

.boardcontentlist-notice-item-date {
  font-size: 11px;
  line-height: 13px;

  /* 프로필날짜조회수 */

  color: #888888;
  margin-bottom: 3px;
}

.boardcontentlist-notice-item-title {
  font-size: 15px;
  line-height: 23px;

  /* qna타이틀내용 */

  color: #171717;
}

.boardcontentlist-notice-item-title-unfold {
  font-size: 15px;
  line-height: 23px;

  /* qna타이틀내용 */
  font-weight: bold;
  color: #171717;
}

.ion-color-boardcontentlist-notice-item-arrow {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #89e2a980;
  --ion-color-contrast: #2d9cdb;
  --ion-color-tint: #89e2a980;
  --box-shadow: none;

  color: #2d9cdb;
}

.boardcontentlist-notice-item-body {
  margin-top: 10px;
  padding-bottom: 16px;

  font-size: 15px;
  line-height: 23px;
  padding-right: 16px;

  /* 프로필아이디 */

  color: #6b6b6b;
}
