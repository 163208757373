.gamification-report-container {
  position: relative;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.ion-color-gamification-report-close {
  position: absolute;
  top: 10px;
  right: 0px;
  --color: #000;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  margin: 0px;
}

.gamification-report-close-icon {
  width: 30px;
  height: 30px;
}

.gamification-report-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gamification-report-main-background-help {
  background-color: #13d0d8;
}

.gamification-report-main-background-answerer {
  background-color: #ff626a;
}

.gamification-report-main-image {
  width: 270px;
  height: 270px;
  margin-top: -10px;
}

.gamification-report-main-message {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "YanoljaYache";
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.gamification-report-main-message-point {
  color: #ffc45b;
}

.gamification-report-info-container {
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.gamification-report-info-title {
  margin-left: 15px;
  margin-bottom: 20px;

  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}

.gamification-report-info-user-list-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.gamification-report-info-user-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80px;
  margin-left: 20px;
}

.gamification-report-info-user-item-profile {
  min-width: 80px;
  min-height: 80px;
  border-radius: 40px;
}

.gamification-report-info-user-item-nickname {
  white-space: nowrap;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  max-width: 80px;
  text-overflow: ellipsis;
  color: #000000;
  width: 100%;
  text-align: center;
}

.gamification-report-info-weekly-report-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px 15px;
}

.gamification-report-info-weekly-report-item-container {
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gamification-report-info-weekly-report-count-container {
  width: 96px;
  height: 96px;
  border-radius: 48px;
  padding: 8px;
  margin-bottom: 10px;
  position: relative;
}

.gamification-report-info-weekly-report-count-container-1 {
  background: #fa9d44;
}

.gamification-report-info-weekly-report-count-container-2 {
  background: #44d7b6;
}

.gamification-report-info-weekly-report-count-container-3 {
  background: #b61fe0;
}

.gamification-report-info-weekly-report-count-inner {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  padding: 8px;
  background: #fff8;
}

.gamification-report-info-weekly-report-count {
  width: 64px;
  height: 64px;
  padding: 0px 2px;
  border-radius: 32px;
  background: #fff;
}

.gamification-report-info-weekly-report-count-text {
  width: 100%;
  height: 100%;
  font-family: "YanoljaYache";
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  white-space: nowrap;
  text-align: center;
  color: #171717;
  position: absolute;
  top: 0px;
  left: 0px;
  padding-top: 16px;
}

.gamification-report-info-weekly-report-description {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;

  color: #171717;
}
