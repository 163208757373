.ion-color-category-select-button-checked {
  /* padding-left: 14px; */
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  /* padding-right: 6px; */
  height: 32px;
  --ion-color-base: #2d9cdb;
  border: 1px solid #2d9cdb;
  box-sizing: border-box;
  background: #2d9cdb;
  border-radius: 16px;
  --border-radius: 16px;
  --padding-start: 14px;
  --padding-end: 6px;

  font-size: 15px;
  line-height: 21px;
  font-weight: bold;
  --box-shadow: none;

  color: #fff;
}

.ion-color-category-select-button-unchecked {
  /* padding-left: 14px;*/
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  /* padding-right: 6px; */
  height: 32px;
  --ion-color-base: #0000;
  border: 1px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 16px;
  --border-radius: 16px;
  --padding-start: 14px;
  --padding-end: 6px;

  font-size: 15px;
  line-height: 21px;
  --box-shadow: none;

  color: #000000;
}

.category-select-icon-container {
  margin-left: 6px;
  height: 24px;
  width: 24px;
}

.category-select-icon {
  border-radius: 12px;
  background: #fff;
  color: #2d9cdb;
  height: 24px;
  width: 24px;
  font-size: 24px;
}
