.ion-color-viewer-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 53px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 53px;
}

.viewer-content {
  position: relative;
  width: 100%;
  height: calc(100% - 43px);
  --background: #dae8ef;
  --overflow: none;
  /* -webkit-overflow-scrolling: touch; */
}

.viewer-container {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #eff3f5;
  /* padding-top: 16px; */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.viewer-container-with-suggest {
  height: calc(100% - 25px);
  margin-bottom: -13px;
}

.viewer-header {
  height: 53px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.viewer-title {
  color: #393939;
}

.viewer-footer {
  height: 47px;
}

.ion-color-viewer-reply {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;
  height: 47px;
  margin: 0px;
}

.viewer-reply-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.viewer-reply-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.02em;
  text-align: left;
  flex-grow: 1;

  color: #6b6b6b;
}

/* .viewer-reply-button-container {
  width: 40px;
  height: 40px;
  border-radius: 13px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  background: #fd6128;
  padding: 8px;
}

.viewer-reply-button {
  width: 24px;
  height: 24px;
  color: #fff;
} */

.viewer-reply-suggest {
  /* position: absolute;
    bottom: 0px; */
  position: relative;
  box-sizing: border-box;
  width: calc(100% - 16px);
  background: #2d9cdb;
  /* left: 0px; */
  border-radius: 13px 13px 0px 0px;

  margin-right: 8px;
  margin-left: 8px;

  padding-right: 16px;
  padding-left: 16px;
  padding-top: 10.5px;
  padding-bottom: 10.5px;

  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.02em;
  z-index: 2;
  color: #ffffff;
}

.viewer-content-waiting-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.viewer-content-waiting {
  height: 30px;
  width: 30px;
}

.viewer-nudge-button {
  width: 70%;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  background: #fddd0a;
  color: #381f1f;
}

.viewer-nudge-icon {
  width: 24px;
  height: 24px;
  margin: 0px 20px;
}
