.file-input-button-file-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    width: 100%;
    height: 100%;
}

.file-input-button-file {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.file-input-button-icon {
    width : var(--icon-size);
    height: var(--icon-size);
}

.ion-color-file-input-button {
    --ion-color-base: var(--icon-color);
    margin: 0px;
    --padding-start: 0px;
    --padding-end: 0px;
    height: 100%;
    width: 100%;
}