.boardcontentlist-simpleitem-container {
  /* margin-right: 8px;
    margin-left: 8px; */
  width: 100%;
  background-color: #fff;
  --padding-start: 0px;
  --inner-padding-end: 0px;
}

.boardcontentlist-simpleitem-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;
}

.boardcontentlist-simpleitem-odd {
  background: rgba(196, 196, 196, 0.1);
}

.boardcontentlist-simpleitem-category {
  width: 60px;
  min-width: 60px;
  padding-left: 17px;
  padding-right: 17px;
  font-size: 8px;
  line-height: normal;
  text-align: center;

  color: #6b6b6b;
}

.boardcontentlist-simpleitem-body-container {
  padding-top: 8px;
  padding-bottom: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
}

.boardcontentlist-simpleitem-body {
  width: 100%;
  max-width: 100%;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  color: #171717;
  padding-bottom: 8px;
  display: inline-block;
  /* display: none; */
}

.boardcontentlist-simpleitem-info-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 9px;
  line-height: normal;

  color: #888888;
}

.boardcontentlist-simpleitem-info-nickname {
  font-weight: bold;
  margin-right: 10px;
}

.boardcontentlist-simpleitem-info-date {
  flex-grow: 1;
}

.boardcontentlist-simpleitem-info-item {
  margin-left: 7px;
}

.boardcontentlist-simpleitem-info-item-cnt {
  margin-left: 3px;
}
