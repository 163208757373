.myprofile-content {
  width: 100%;
  height: calc(100% - 43px);
  --background: #dae8ef;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.myprofile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  align-items: center;
}

.myprofile-header {
  height: 43px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.myprofile-card {
  margin: 8px;
  /* height: 300px; */
  background: #ffffff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  transform: translateZ(0);
  overflow: show;
  position: relative;
}

.myprofile-card-more-container{
    position: absolute;
    right: 10px;
    top: 10px;
}

.ion-color-myprofile-card-more {
    --ion-color-base: #313131;
    --ion-color-contrast: #313131;
    --ion-color-shade: #aeaeae;
    --ion-color-tint: #aeaeae;
    --padding-end: 0px;
    height: 20px;
}

.myprofile-card-container {
  height: 100%;
  width: 100%;
}

.ion-color-myprofile-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 43px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 43px;

  font-size: 16px;
  line-height: normal;

  color: #a1a0a5;
}

.ion-color-profile-change-button {
  position: absolute;
  top: 10px;
  right: 8px;
  height: 30px;
  width: 75px;
  --ion-color-base: #2d9cdb;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  --border-radius: 15px;
  --padding-start: 0px;
  --padding-end: 0px;

  font-size: 11px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #fff;
}

.ion-color-profile-cart-button {
  position: absolute;
  top: 52px;
  right: 8px;
  height: 30px;
  width: 75px;
  --ion-color-base: #fff;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  --border-radius: 15px;
  --border-width: 1px;
  --border-style: solid;
  --border-color: #2d9cdb;
  --padding-start: 0px;
  --padding-end: 0px;

  font-size: 11px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #2d9cdb;
}

.myprofile-profile-container {
  margin-top: 20px;
  width: 100px;
  height: 100px;
  position: relative;
}

.myprofile-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: fill;
}

.myprofile-profile-upload-button-container {
  height: 28px;
  width: 28px;
  bottom: 5px;
  right: 5px;
  position: absolute;
}

.myprofile-profile-upload-button-inner {
  height: 100%;
  width: 100%;
  position: relative;
}

.myprofile-profile-upload-button {
  height: 100%;
  width: 100%;
  --padding-start: 25px;
  --padding-end: 25px;
  margin: 0px;
  background: #2d9cdb;
  /* 시그니처컬러 */
  border-radius: 14px;

  color: #fff;
}

.ion-color-myprofile-profile-upload-button {
  height: 100%;
  width: 100%;
  --ion-color-base: #2d9cdb;
  --border-radius: 50%;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
  color: #fff;
}

.myprofile-profile-upload-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.myprofile-profile-upload-button-icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
  color: #fff;
}

.myprofile-nickname-container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.myprofile-nickname {
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: none;

  color: #171717;
}

.myprofile-location {
  background: #ffffff;
  opacity: 0.5; /* 시그니처컬러 */
  border: 0.5px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;

  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-transform: lowercase;

  color: #6b6b6b;
}

.myprofile-description {
  width: 250px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 35px;

  font-size: 12px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: lowercase;

  color: #6b6b6b;
}

.myprofile-counts-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.myprofile-count-container {
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.myprofile-count-border {
  border-right: 0.6px solid #dadada;
}

.myprofile-count {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.005em;
  text-transform: lowercase;

  color: #6b6b6b;
}

.myprofile-count-type {
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.005em;
  text-transform: lowercase;

  color: #2d9cdb;
}

.ion-color-myprofile-segment {
  margin-top: 15px;
  width: calc(100% - 16px);
  margin-left: 8px;
  margin-right: 8px;
  height: 41px;
  border-radius: 13px 13px 0px 0px;
  transform: translateZ(0);
  overflow: show;
  background-color: #a1a0a5;
  justify-items: left;
  --ion-color-base: #0000;
  /* margin-bottom: -16px; */
}

.myprofile-segment-button {
  height: 41px !important;
  min-height: 41px;
  min-width: 80px;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #ffffff !important;
  border-radius: 13px 13px 0px 0px;
  transform: translateZ(0);
  overflow: show;
  background: #a1a0a5 !important;
}

.myprofile-segment-button.segment-button-checked {
  background: #2d9cdb !important;

  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
}

.myprofile-segment-button-text {
  width: 100%;
  height: 41px;
  padding-top: 7px;
}

.myprofile-segment-title {
  position: relative;
  border-radius: 13px 13px 0px 0px;
  background: #fff;
  width: calc(100% - 16px);
  margin-left: 8px;
  margin-right: 8px;
  height: 37px;
  margin-top: -13px;
  padding-top: 14px;
  padding-left: 16px;

  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-transform: capitalize;

  color: #2d9cdb;
}

.myprofile-segment-title-highlight {
  font-weight: bold;
}

.myprofile-swipe {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.myprofile-list-container {
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  height: calc(100vh - 120px);
  overflow: hidden;
}
