.allinone-detail-head-container {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
  padding: 0px 20px;

  .allinone-detail-logo {
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
    object-fit: contain;
    border: 1px solid #e4ebed;
    border-radius: 40px;
    transform: translateZ(0);
  }
}

.allinone-posts-container {
  padding: 0px 20px;
}

.allinone-information-container {
  padding: 0px 20px;

  .allinone-information-title {
    padding-top: 20px;
    padding-bottom: 8px;
  }

  .allinone-information-body {
    padding-bottom: 20px;
  }
}

.allinone-footer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
}
