.attachment-container {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 0.5px solid #dadada;
  box-sizing: border-box;
  border-radius: 13px;
  padding-left: 4px;
  align-items: center;
  margin-top: 5px;
}

.attachment-icon {
  width: 52px;
  height: 52px;
}

.attachment-info-container {
  margin-left: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.attachment-info-filename {
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  color: #6a6a6a;
}

.attachment-info-size {
  font-size: 12px;
  line-height: 13px;
  color: #cecece;
}

.ion-color-attachment-delete {
  --ion-color-base: #fff;
  --color: #ececec;
  --box-shadow: none;
}
