.register-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.register-title-sub {
  padding-top: 130px;

  font-size: 20px;
  line-height: 31px;

  color: #171717;
}

.register-title {
  font-size: 20px;
  font-weight: bold;
  color: #171717;
}

.register-title-margin-top {
  margin-top: 25px;
}

.register-progress-container {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 25px;
}

.register-progress-done {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  margin-right: 20px;
  border: 2px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  font-size: 12px;
  line-height: 24px;
  text-align: center;

  color: #2d9cdb;
}

.register-progress-undone {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  margin-right: 20px;
  border: 2px solid #a1a0a5;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
  
  font-size: 12px;
  line-height: 24px;
  text-align: center;

  color: #a1a0a5;
}

.register-popup-close {
  --color: #ececec;
  height: 17px;
  --padding-start: 13px;
  --padding-end: 13px;
  margin: 0px;
}

.register-popup-close-icon {
  width: 14px;
  height: 14px;
}

.register-popup-search-icon {
  width: 16px;
  height: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 14px;
  color: #2d9cdb;
}

.register-navigate-container {
  width: 100%;
  height: 61px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.ion-color-register-navigate-inactive {
  --ion-color-base: #0000;
  --box-shadow: none;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  height: 40px;

  font-size: 17px;
  line-height: normal;
  text-align: right;

  color: #a1a0a5;
}

.register-navigate-space {
  flex-grow: 1;
}

.ion-color-register-navigate-active {
  --ion-color-base: #0000;
  --box-shadow: none;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  height: 40px;

  font-size: 17px;
  line-height: normal;
  text-align: right;
  font-weight: bold;

  color: #fd6128;
}

.register-chat-guide {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  text-align: right;
  padding-right: 5px;
}

.register-chat-kakao {
  height: 40px;
  width: 82px;
}
