.board-content-list-waiting-container {
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.board-content-list{
    padding-left: 8px;
    padding-right: 8px;
}