.ion-color-toggle-checked {
    width: var(--size, 24px);
    height: var(--size, 24px);
    min-width: var(--size, 24px);
    min-height: var(--size, 24px);
    --radius: calc(var(--size) * 0.5);
    border-radius: var(--radius, 12px);
    --ion-color-base: #2D9CDB;
    border: 2px solid #2D9CDB;
    --padding-start: 0px;
    --padding-end: 0px;
    overflow: hidden;
    transform: translateZ(0);
    z-index: 0;
}

.ion-color-toggle-unchecked {
    width: var(--size, 24px);
    height: var(--size, 24px);
    min-width: var(--size, 24px);
    min-height: var(--size, 24px);
    --radius: calc(var(--size) * 0.5);
    border-radius: var(--radius, 12px);
    --ion-color-base: #A1A0A5;
    border: 2px solid #A1A0A5;
    --padding-start: 0px;
    --padding-end: 0px;
    overflow: hidden;
    transform: translateZ(0);
    z-index: 0;
}

.check-toggle-icon {
    --icon-size: calc(var(--size) * 0.8);
    width: var(--icon-size,14px);
    height: var(--icon-size,14px);
}
