.suggestion-item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.suggestion-item-category {
  padding-left: 12px;
  padding-right: 12px;
  height: 28px;

  font-size: 12px;
  line-height: 130%;
  /* or 16px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #171717;

  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 20.5px;
}

.suggestion-item-category-highlight {
  color: #fd6128;
}

.suggestion-item-body-container {
  padding-top: 10px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 15px;
  min-height: 120px;
  margin-top: 13px;
  margin-right: 15px;
  margin-left: 15px;
  width: calc(100% - 30px);

  display: flex;
  flex-direction: column;

  border: 1px solid #afafaf;
  box-sizing: border-box;
  /* box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.22); */
  border-radius: 4px;

  font-size: 16px;
  line-height: 25px;
  /* or 25px */

  letter-spacing: -0.01em;

  /* 프로필아이디 */

  color: #171717;
}

.suggestion-item-body-title {
  font-weight: 800;
}

.suggestion-item-body {
  width: 100%;
  height: 100%;
}

.suggestion-item-information-container {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  font-size: 10px;
  line-height: 10px;

  /* 프로필날짜조회수 */

  color: #888888;
}

.suggestion-item-information-title {
  margin-left: 10px;
}

.suggestion-item-information-count {
  margin-left: 5px;
}
