.register0-container {
  height: 100%;
  padding-top: 50px;
  padding-bottom: 70px;
  padding-left: 25px;
  padding-right: 25px;
  background: #fff;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  position: relative;
}

.register0-waiting-message {
  margin-top: 15px;
  margin-bottom: -70px;

  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  letter-spacing: -0.02em;

  /* 프로필아이디 */

  color: #6b6b6b;
}

.register0-title-description {
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  color: #fd6128;
  margin-bottom: 24px;
}

.register0-service-description-container {
  font-size: 20px;
  line-height: 37px;
  font-weight: bold;

  color: #2d9cdb;
  white-space: nowrap;
  margin-bottom: 60px;
}

.register0-service-description-hightlight {
  font-size: 22px;
  font-weight: bold;
}

.register0-select-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 20px 20px 100px 20px;
}

.register0-select-middle {
  flex-grow: 1;
}
.register0-select-text {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 20px;
}

.register0-image-container {
  width: 100%;
  margin-top: 160px;
  position: relative;
}

.register0-image {
  position: absolute;
  right: -25px;
  width: 340px;
}

.register0-select-pharamacist {
  width: 150px;
  height: 150px;
  --background: #2d9cdb;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  --border-radius: 13px;

  font-size: 17px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
  color: #ffffff;
}

.register0-select-student {
  width: 150px;
  height: 150px;
  --background: #a1a0a5;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  --border-radius: 13px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;

  color: #ffffff;
}
