.boardlistitem-container {
  width: 100%;
  /* --border-radius: 2px; */
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08); */
  /* margin-bottom: 8px; */
  background-color: #fff;
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #e4ebed;
}

.boardlistitem-notice-container {
  /* border: 1px solid #2d9cdb; */
}

.boardlistitem-body-inactive {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff80;
}

.boardlistitem-banner {
  width: 100%;
  margin-bottom: 4px;
}

.boardlistitem-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
}

.boardlistitem-notice-inner {
  align-items: center;
}

.boardlistitem-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 23px;
  font-weight: 800;
  font-size: 17px;
  letter-spacing: -0.04em;
  color: #171717;
  /* margin-bottom: 16px; */
  margin-bottom: 4px;
}
.boardlistitem-notice-category-container {
  background: #fffcab;
  font-weight: 800;
  font-size: 14px;
  line-height: 100%;
  /* or 14px */

  text-align: center;
  letter-spacing: -0.04em;
  color: #494949;
  padding: 3px 8px;

  margin-bottom: 10px;
}

.boardlistitem-notice-title-container {
  width: 100%;
  line-height: 23px;
  text-align: center;
  font-weight: 800;
  font-size: 17px;
  letter-spacing: -0.04em;
  color: #171717;
}

.boardlistitem-profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  padding: 0 20px;
  /* identical to box height, or 15px */

  letter-spacing: -0.02em;
  text-transform: lowercase;

  /* 프로필아이디 */

  color: #6b6b6b;
  margin-bottom: 10px;
}

.boardlistitem-profile {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 7.5px;
}

.boardlistitem-title {
  flex-grow: 1;
}

.boardlistitem-title-icon {
  vertical-align: middle;
  margin-right: 5px;
  width: 17px;
  height: 17px;
}

.boardlistitem-title-icon {
  margin-right: 4px;
  /* min-width: 30px; */
  /* height: 17px; */
  height: 20px;
  position: relative;
}

.boardlistitem-body-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.boardlistitem-body-container {
  margin-bottom: 16px;
  padding: 0 20px;
}

.boardlistitem-body {
  line-height: 22px;

  font-size: 14px;
  color: #171717;

  flex-grow: 1;
}

.boardlistitem-body-text {
  width: 100%;
}

.boardlistitem-body-image {
  margin-left: 8px;
  min-width: 72px;
  width: 72px;
  height: 72px;
  border-radius: 8px;
  object-fit: cover;
}

.boardlistitem-body-big-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  margin-top: 10px;
}

.boardlistitem-inner-info-time {
  font-size: 11px;
  line-height: normal;
  color: #888888;
  flex-grow: 1;
}

.boardlistitem-inner-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.item-native {
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  padding-inline-start: 0px;
  padding-left: 0px;
}

.item-inner {
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  padding-inline-end: 0px;
}

.boardlistitem-inner-info-icon {
  vertical-align: middle;
  width: 13px;
  height: 13px;
  color: #888888;
  margin-right: 4px;
}

.boardlistitem-inner-info-count {
  margin-left: 5px;
  font-size: 10px;
  color: #888888;
}

.boardlistitem-inner-info-devider {
  font-size: 10px;
  color: #888888;
  margin-left: 3px;
  margin-right: 3px;
}

.boardlistitem-inner-info-item {
  margin-left: 10px;
  font-size: 10px;
  color: #888888;
}

.boardlistitem-inner-info-item-reply {
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
  color: #2d9cdb;
}

.boardlistitem-inner-info-item-noreply {
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  background: #fd6128;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
}

.boardlistitem-scrap-button {
  position: absolute;
  padding: 0px;
  top: -9px;
  right: 16px;
  width: 40px;
  height: 45px;
  --padding-start: 0px;
  --padding-end: 0px;
}

.boardlistitem-toolbar-container {
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.boardlistitem-toolbar-button {
  min-width: 25%;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;

  letter-spacing: -0.02em;

  color: #6b6b6b;

  visibility: visible !important;
}

.boardlistitem-toolbar-button-on {
  color: #2d9cdb !important;
  visibility: visible !important;
}

.boardlistitem-toolbar-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.boardlistitem-toolbar-button-devider {
  border-right: 0.5px solid #afafaf;
  height: 15px;
}
