@import './../../../_design/color.scss';

.seminar-view-sticky-top{
    position: sticky;
    z-index: 100;
}


.seminar-view-information-icon{
    width: 18px;
    height: 18px;
}

.seminar-view-information-text{
    margin-left: 4px !important; 
    margin-right: 13px !important;
}