/*Color*/
:root {
  --primary: #1fbcff;
  --primary_shadow: #008fcc;
  --blue400: #1f75ff;
  --blue300: #478eff;
  --blue100: #99c0ff;
  --red300: #ff4757;
  --gray900: #0f2034;
  --gray800: #273648;
  --gray700: #3f4d5d;
  --gray600: #4b5b69;
  --gray500: #5f6d79;
  --gray400: #7e8a95;
  --gray300: #9ba5af;
  --gray200: #b7c0c6;
  --gray100: #cdd5d9;
  --gray50: #e4ebed;
  --gray20: #eff3f5;
  --gray15: #f2f4f5;
  --gray10: #f7f8f9;
  --gray0: #ffffff;
}

.allinone-introduction {
  font-size: 62.5%;
  line-height: 1.285;

  text-align: center;
  overflow-x: hidden;
  background: var(--gray15);

  main {
    padding-bottom: 60px;
    background: var(--blue400);
  }
  section {
    margin: 0 auto;
    padding: 80px 20px 0px 20px;
    // width: 88vw;
    // max-width: 360px;
  }
  h1 {
    padding: 20px 0;
    font-size: 2.8em;
    font-weight: 700;
    color: var(--blue100);
  }
  h2 {
    margin-bottom: 8px;
    font-size: 2.2em;
    font-weight: 700;
    color: var(--blue400);
  }
  p {
    font-size: 1.6em;
    font-weight: 400;
    color: var(--gray700);
  }
  .emphasis {
    color: var(--gray0);
  }

  /*Questions*/
  .questions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(/images/img_04.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 200px;
  }
  .questions .qbox {
    margin: 8px 0;
    padding: 1.2em 1.6em;
    width: fit-content;
    font-size: 18px;
    font-weight: 600;
    color: var(--gray700);
    background: var(--gray0);
    box-shadow: 0px 6px 14px rgba(15, 32, 52, 0.1);
    border-radius: 12px;
  }
  .q-01 {
    transform: rotate(-2.79deg);
  }
  .q-02 {
    opacity: 0.9;
    transform: rotate(-2.5deg);
  }
  .q-03 {
    opacity: 0.8;
  }
  .q-04 {
    opacity: 0.7;
    transform: rotate(1.37deg);
  }
  .q-05 {
    opacity: 0.6;
    transform: rotate(-1.47deg);
  }
  .q-06 {
    opacity: 0.5;
  }
  .dots p {
    display: flex;
    flex-direction: column;
    font-size: 6em;
    line-height: 0.5;
    color: var(--blue300);
  }

  /*Description*/
  .description {
  }
  .description article {
    margin: 40px auto;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    padding: 40px;
    background: var(--gray0);
    box-shadow: 0px 6px 14px rgba(15, 32, 52, 0.1);
    border-radius: 24px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .description .img-box {
    width: 100%;
    text-align: center;
  }
  .description img {
    margin-bottom: 32px;
    max-width: 180px;
  }

  /*Recommend*/
  .recommend {
    padding-bottom: 120px;
  }
  .recommend h1 {
    margin-bottom: 32px;
    color: var(--gray700);
  }
  .recommend p {
    margin-bottom: 12px;
    font-size: 1.8em;
  }
  .recommend img {
    max-width: 40px;
  }
  h6 {
    font-size: 14px;
    font-weight: 500;
    color: var(--gray600);
  }
  .recommend article {
    margin: 40px auto;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: var(--gray0);
    box-shadow: 0px 6px 14px rgba(15, 32, 52, 0.1);
    border-radius: 24px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .recommend ul {
    width: 100%;
  }
  .recommend article li {
    margin: 16px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .recommend article li div {
    width: 80px;
  }
}
