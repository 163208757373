@import './../../_design/color.scss';

.link-container{
    width: 100%;
    border-radius: 12px;
    background-color: $Gray8;
    --size: 60px;
    --radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;

    .link-info-container {
        flex-grow: 1;
    }
}

.link-plain{
    text-decoration-line: underline;
}

.link-youtube-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    .link-youtube {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}

