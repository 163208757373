.ion-color-html-composer-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 53px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 53px;

  font-size: 16px;
  line-height: normal;
}

.html-composer-content {
  height: 300px;
  overflow-y: scroll;
  /* transform: translateZ(0); */
}
.html-composer-container {
  width: 100%;
  height: 100%;
  /* overflow-y: hidden; */

  /* display: flex; */
  /* flex-direction: column; */
  padding-left: 17px;
  padding-right: 17px;
  /* padding-bottom: 40px; */
  overflow-y: auto;
  transform: translateZ(0);
}

.html-composer-button {
  font-size: 16px;
  line-height: normal;
  color: #2d9cdb;
  font-weight: bold;
  padding-right: 10px;
}

.ion-color-html-composer-show-parent {
  background: #fafafa;
  /* 시그니처컬러 */

  border: 1px solid #2d9cdb;

  --box-shadow: none;

  color: #2d9cdb;
  --ion-color-base: #2d9cdb;
  margin-right: 20px !important;
}

.html-composer-show-parent {
  position: relative;

  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  letter-spacing: -0.02em;
  padding: 0px 10px;
}

.html-composer-show-parent-new {
  color: #fff;
  text-shadow: -1px -1px 0 #eb5757, 1px -1px 0 #eb5757, -1px 1px 0 #eb5757, 1px 1px 0 #eb5757;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.html-composer-show-parent-spinner {
  height: 26px;
  width: 26px;
}

.html-composer-button-disabled {
  font-size: 16px;
  line-height: normal;
  color: #c4c4c4;
  padding-right: 10px;
}

.ion-color-html-composer-checkbox {
  margin-right: 5px;
  --background-checked: #2d9cdb;
  --border-color-checked: #2d9cdb;
  --border-color: #c4c4c4;
  --checkmark-color: #fff;
}

.html-composer-profile-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.html-composer-title-container {
  width: calc(100% - 14px);
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 0.5px solid #ececec;
}

.html-composer-title-icon {
  height: 17px;
  width: 17px;
  margin-right: 10px;
  margin-top: 6px;
}

.html-composer-advertise-input {
  z-index: 2;
  flex-grow: 1;
  font-weight: 800;

  font-size: 15px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #171717;
  --padding-start: 0px;
  --padding-end: 0px;
  outline-style: none;
  border: none;
}

.html-composer-title-input {
  z-index: 2;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.04em;
  color: #171717;
  --padding-start: 0px;
  --padding-end: 0px;
  outline-style: none;
  border: none;
}

.html-composer-title-input::placeholder {
  color: #b7c0c6;
}
.html-composer-title-input::-webkit-input-placeholder {
  color: #b7c0c6;
}

.html-composer-title-input-container {
  padding: 14px 10px;
  flex-grow: 1;
  position: relative;
  align-items: center;
}

.html-composer-hidden {
  display: none;
}

.html-composer-title-placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-left: 8px;
  font-weight: 800;

  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.04em;

  color: #c0c0c0;
  text-align: left;
}

.html-composer-title-placeholder-sub {
  font-size: 15px;
}

.html-composer-body-container {
  position: relative;
  width: 100%;
  min-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
  /* margin-left: 7px; */
  /* margin-right: 7px; */
  /* padding-bottom: 10px; */
}

.html-composer-reommends-container {
  position: absolute;
  width: 100%;
  max-width: 100%;
  bottom: 0px;
  left: 0px;
  z-index: 3;
}

.html-composer-content-placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  font-size: 14px;
  line-height: 22px;

  color: #ababab;
}

.html-composer-body {
  position: relative;
  background: #0000;
  z-index: 2;
  height: auto;
  width: 100%;
  border: 1px;

  font-size: 14px;
  line-height: 22px;
  padding: 0px;
  color: #171717;
  outline-style: none;
}
.html-composer-images-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.html-composer-images-preview {
  width: 72px;
  height: 72px;
  border-radius: 13px;
  border-width: 0px;
  object-fit: cover;
  margin-right: 8px;
}

.html-composer-files-container {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: calc(100% - 14px);
  margin-left: 7px;
  margin-right: 7px;
}

.html-composer-toolbar-button-container {
  /* overflow: hidden; */
  display: inline-block;
  position: relative;
  height: 24px;
  width: 28px;
  margin: 0px;
  padding: 3px 5px;
  white-space: nowrap;
}

.ion-color-html-composer-toolbar-button {
  --ion-color-base: #494949;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --padding-start: 0px;
  --padding-end: 0px;
  height: 24px;
  width: 28px;
  margin: 0px;
}

.html-composer-toolbar-button-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 28px;
  height: 24px;
}

.html-composer-toolbar-button-icon {
  width: 18px;
  height: 18px;
}

.ql-toolbar.ql-snow {
  color: #494949;
  background: #fafafa;
  border: 1px solid #f5f5f6;
  border-radius: 0px;
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 12px;
}

.ql-container.ql-snow {
  border: none !important;
  height: calc(100% - 102px);
}

.ql-editor {
  -webkit-user-select: text;
  max-height: none !important;
  padding: 0px !important;

  font-size: 14px !important;
  line-height: 22px !important;
  color: #171717;
}

.ql-editor.ql-blank::before {
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 22px !important;

  color: #ababab;
}

.ql-mention-list-item.selected {
  background-color: #dae8ef !important;
}

.ql-mention-list-item {
  padding: 0 !important;
  height: 40px;
}

.mention {
  border-radius: 6px !important;
  padding: 2px !important;
  background-color: #dae8ef !important;
  font-weight: bold;
}

.html-composer-search-medicine-item-container {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.html-composer-search-medicine-item-type {
  color: #2d9cdb;
  display: flex;
  padding-top: 3px;
  flex-direction: column;
  align-items: center;
  min-width: 35px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #2d9cdb;
  font-weight: 800;

  font-size: 10px;
  line-height: 14px;
  margin-right: 10px;
  letter-spacing: -0.04em;
}

.html-composer-search-medicine-item-info {
  flex-grow: 1;
}

.html-composer-search-medicine-item-name {
  font-weight: 800;

  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.04em;
  color: #171717;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.html-composer-search-medicine-item-description {
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.04em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2d9cdb;
}

.html-composer-component {
  background: #2d9cdb80;
  border-radius: 4px;
  padding: 0px 4px;
}

.html-composer-anonymous-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.html-composer-anonymous-toggle {
  zoom: 0.6;
  --background-checked: #2d9cdb;
  --background: #a1a0a5;
}

.html-composer-anonymous-button {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #2d9cdb;
}

.html-composer-anonymous-button-disabled {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #a1a0a5;
}

.html-composer-subcategory-container {
  width: 100%;
  background: #f7f8f9;
  padding: 12px;
  margin-bottom: 20px;

  border: 1px solid #e4ebed;
  border-radius: 8px;
}

.html-composer-subcategory-invite-description-title {
  margin-bottom: 6px;
}

.html-composer-subcategory-invite-container {
  width: 100%;
  background: #fff;
  border: 1px solid #e4ebed;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 12px;
  align-items: flex-start;
}

.html-composer-subcategory-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.04em;

  color: #000000;
  margin-right: 15px;
  margin-top: 3px;
  min-width: 56px;
}

.html-composer-subcategory-items-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
}

.html-composer-subcategory-item-toggle {
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #e4ebed;
  box-sizing: border-box;
  border-radius: 18px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3f4d5d;
  margin-bottom: 10px;
  margin-right: 10px;
}

.html-composer-subcategory-item-toggle-selected {
  background: #2d9cdb !important;
  color: #fff !important;
}

.html-composer-subcategory-done-button {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */

  /* 시그니처컬러 */

  color: #2d9cdb;
}

.html-composer-subcategory-body {
  flex-grow: 1;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 13px */

  /* Gray 2 */

  color: #4f4f4f;
  margin-top: 4px;
}

.html-composer-subcategory-modify-button {
  min-width: 32px;
  height: 21px;
  background: #2d9cdb;
  border-radius: 8px;
  color: #fff;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
}
