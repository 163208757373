.select-popup-icon {
  width: 36px;
  height: 36px;
  margin-bottom: 29px;
}

.select-popup-image {
  width: 100%;
  max-width: 360px;
  margin-bottom: 20px;
}

.select-popup-title-container {
  width: 100%;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 100%;

  letter-spacing: -0.2px;

  color: #000000;
}

.select-popup-title-highlight {
  color: #2d9cdb;
}

.select-popup-body-container {
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.02em;

  color: #6b6b6b;
}

.select-popup-body-item-out-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0px;
}

.select-popup-body-item-out-bordered-container {
  border-bottom: 0.5px solid #a1a0a5;
}

.select-popup-body-item-container {
  flex-grow: 1;
}

.select-popup-body-item-checkbox {
  width: 17px;
  height: 17px;
}

.ion-color-select-popup-close-button {
  --color: #ececec;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  margin: 0px;
}

.select-popup-close-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.select-popup-close-button-icon {
  width: 14px;
  height: 14px;
}
