.test-div-container {
  width: 100%;
  height: 100%;
  background-color: rgb(238, 255, 0);
  display: flex;
  flex-direction: column;
  /* max-height: 300px; */
  /* margin-bottom: 40px; */
}

.test-action-sheet {
  background-color: #f00 !important;
  --color: #fff;
}

.ion-color-test-boards-toolbar {
  --ion-toolbar-color-unchecked: #39393980;
  --ion-toolbar-color-checked: #171717;
  --ion-color-base: #2d9cdb;
  --ion-color-shade: #393939;
  --ion-color-tint: #393939;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  --padding-end: 0px;

  --min-height: 53px;
  height: 53px;
}

.test-boards-header {
  height: 53px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.test-boards-segment-button {
  --indicator-color-checked: #f00 !important;
  --indicator-color: #0f0 !important;
  --padding-start: 10px;
  --padding-end: 10px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  text-transform: none;

  font-weight: 800;
  font-size: 17px;
  line-height: 19px;
  letter-spacing: -0.1em;
  height: 53px;
  min-height: 53px;
  /* 상단바 제목_비활성화 */
  border-bottom: 4px solid #0f0;

  color: rgb(181, 183, 214) !important;
  /* width: 80px; */
  min-width: 0px;
  /* max-width: 0px; */
}

.segment-button-indicator {
  height: 4px !important;
}
.test-boards-segment-button-text {
  width: 100%;
  height: 30px;
  padding-top: 5px;
  line-break: 30px;
}

.test-boards-segment-button.segment-button-checked {
  font-weight: 800;

  font-size: 17px;
  line-height: 19px;
  border-bottom: 4px solid #f00;

  color: #ffffff !important;
}

.ion-color-test-boards-segment {
  --ion-color-base: #cecece;
  height: 53px;
  justify-content: left;
  color: #cecece;
  --padding-start: 20px;
  --padding-end: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.test-floating-recommends-container {
  position: fixed;
  bottom: 0px;
  height: 100px;
  width: 100%;
  max-width: 600px;
  background-color: #eef;

  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}
.test-floating-recommends-container::-webkit-scrollbar {
  display: inline-table !important;
  width: 3px;
  height: 10px;
  background: none;
}
.test-floating-recommends-container::-webkit-scrollbar-thumb {
  background: #fdd;
  opacity: 0.4;
}
.test-floating-recommends-container::-webkit-scrollbar-track {
  background: none;
}

.test-floating-recommend-item-container {
  width: 400px;
  height: calc(100% - 20px);
  margin: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #171717;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.sendbird-channel-list {
  width: 100% !important;
}

.sendbird-fileviewer__header {
  max-width: 100% !important;
}

/* .sendbird-fileviewer__header-delete {
    visibility: hidden;
} */
