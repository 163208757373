.number-selector-container {
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #a1a0a5;
  border-radius: 4px;
  position: relative;
}

.number-selector-icon-remove {
  color: #a1a0a5;
}

.number-selector-icon-add {
  color: #171717;
}

.number-selector-info {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 19px */

  text-align: center;
  text-transform: capitalize;
  margin: 0px 10px;

  /* qna타이틀내용 */

  color: #171717;
}

.number-selector-info-disabled {
  color: #a1a0a5 !important;
}

.number-selector-remove {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.number-selector-add {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0px;
  right: 0px;
}
