.profile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  align-items: center;
}

.profile-profile-container {
  margin-top: 20px;
  width: 100px;
  height: 100px;
  position: relative;
}

.profile-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: fill;
}

.profile-profile-upload-button-container {
  height: 28px;
  width: 28px;
  bottom: 5px;
  right: 5px;
  position: absolute;
}

.profile-profile-upload-button-inner {
  height: 100%;
  width: 100%;
  position: relative;
}

.profile-profile-upload-button {
  height: 100%;
  width: 100%;
  --padding-start: 25px;
  --padding-end: 25px;
  margin: 0px;
  background: #2d9cdb;
  /* 시그니처컬러 */
  border-radius: 14px;

  color: #fff;
}

.ion-color-profile-profile-upload-button {
  height: 100%;
  width: 100%;
  --ion-color-base: #2d9cdb;
  --border-radius: 50%;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
  color: #fff;
}

.profile-profile-upload-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.profile-profile-upload-button-icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
  color: #fff;
}

.profile-nickname-container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-nickname {
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: none;
  gap:4px;
  color: #171717;
}

.profile-nickname-blocked {
    font-weight: 800;
    font-family: 'NanumSquare';
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.1em;
    text-transform: none;
    
    color: #FF1F31;
}

.profile-location {
  background: #ffffff;
  opacity: 0.5; /* 시그니처컬러 */
  border: 0.5px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;

  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-transform: lowercase;

  color: #6b6b6b;
}

.profile-description {
  width: 250px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 35px;

  font-size: 12px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: lowercase;

  color: #6b6b6b;
}

.profile-counts-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-count-container {
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.profile-count-border {
  border-right: 0.6px solid #dadada;
}

.profile-count {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.005em;
  text-transform: lowercase;

  color: #6b6b6b;
}

.profile-count-type {
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.005em;
  text-transform: lowercase;

  color: #2d9cdb;
}
