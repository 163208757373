.allinone-container {
  padding: 12px 20px 40px 20px;
  background: #fff;

  .allinone-banner-container {
    width: 100%;
    margin-top: 20px;

    .allinone-banner-cardview {
      --cardview-card-border-radius: 12px;
      --cardview-card-padding: 0px 0px;
    }
  }

  .allinone-card-container {
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    padding: 24px 0px 24px 24px;
    height: 300px;
    transform: translateZ(0);

    .allinone-card-title {
      margin-bottom: 8px;
    }

    .allinone-card-description-img {
      position: absolute;
      right: -15px;
      bottom: 0px;
      width: 60%;
    }

    .allinone-card-more {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      position: absolute;
      bottom: 24px;
      left: 24px;
    }
  }

  .allinone-partners-container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 40px;

    .allinone-card-title {
      margin-bottom: 10px;
    }
  }

  .allinone-categories-container {
    margin-top: 80px;

    .allinone-categories {
      margin-top: 20px;
      padding: 20px 24px;
      border-radius: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 16px 12px;
      transform: translateZ(0);

      .allinone-category-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        white-space: nowrap;
        gap: 4px;

        .allinone-card-description-img {
          width: 40px;
        }
      }
    }

    .allinone-categories-description {
      margin: 20px 0px;
    }
  }
}

.allinone-post-container {
  margin-top: 12px;
  background-color: #fff;
  padding: 32px 20px 20px 20px;
}

.allinone-suggest-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 12px;
}
