.video-play-player-container{
    width: 100%;
    position: relative;
    margin-bottom: -3px;
}

.video-play-player {
    width:100%;
}

.video-icon-container{
    width: 60px;
    height: 60px;
    background: #fff;
    position: absolute;
    padding: 15px;
    border-radius: 30px;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

.video-icon{
    width: 30px;
    height: 30px;
}
