.product-order-container {
  padding: 10px;
}

.product-order-title {
  padding-left: 10px;
  padding-top: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  /* identical to box height, or 17px */

  color: #000000;

  margin-bottom: 10px;
}

.product-order-information-container {
  width: 100%;
  padding: 10px;
  border: 1px solid #e2e2e2;
  margin-bottom: 10px;
}

.product-order-information-title {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 200%;

  color: #000000;
  margin-bottom: 10px;
}

.product-order-information-discount {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 137.88%;
  /* identical to box height, or 18px */

  color: #000000;

  margin-bottom: 5px;
}

.product-order-information-discount-original {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 137.88%;
  /* identical to box height, or 18px */

  text-decoration-line: line-through;

  /* 프로필날짜조회수 */

  color: #888888;
  margin-left: 10px;
}

.product-order-information-price {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 137.88%;
  /* or 23px */

  color: #ae0003;
  margin-bottom: 10px;
}

.product-order-information-count-text {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 137.88%;
  /* identical to box height, or 18px */

  display: flex;
  align-items: center;

  color: #000000;
}

.product-order-pharmacist-warning {
  background: #ffca17;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 130%;
  /* or 17px */

  color: #000000;
  padding: 10px;
  margin-bottom: 10px;
}

.product-order-information-price-total-title {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;

  color: #2667eb;
  margin-left: 10px;
}

.product-order-information-price-total-container {
  min-width: 130px;
}

.product-order-information-price-total-information {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 100%;

  color: #000000;
}

.product-order-recipient-change-button {
  height: 26px;
  padding: 5px;
  border: 1px solid #2667eb;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #2667eb;
}

.product-order-delivery-change-button {
  height: 26px;
  padding: 5px;
  border: 1px solid #eb5757;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #eb5757;
}

.product-order-registration-change-button {
  height: 33px;
  padding: 9px;
  border: 1px solid #eb5757;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #eb5757;
  width: 70%;
  margin: 0px 15%;
}

.product-order-delivery-no-info {
  color: #eb5757 !important;
}

.product-order-guide-container {
  width: 100%;
  background: #eeeeee;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  /* or 16px */

  text-align: center;

  /* 프로필아이디 */

  color: #6b6b6b;
  padding: 10px 0px;
  margin-bottom: 10px;
}

.product-order-buy-button {
  width: 100%;
  background: #346aff;
  color: #fff;
}

.product-order-additional-information-container {
  padding: 10px 15px;
}

.product-order-additional-information-input-container {
  display: flex;
  flex-direction: row;

  height: 40px;
  background: #ffffff;
  /* 하단바비활성화 */
  border: 0.5px solid #a1a0a5;
  box-sizing: border-box;
  border-radius: 20px;
  /* margin-left: 8px; */
  /* margin-right: 8px; */
  margin-top: 15px;
  /* margin-bottom:16px; */
  padding-left: 14px;
}

.product-order-additional-information-input {
  --padding-start: 0px;
  --padding-end: 0px;
  flex-grow: 1;
  --placeholder-color: #a1a0a5;
  --placeholder-opacity: 1;

  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  color: #171717;
}

.product-order-additional-information-title {
  margin-top: 25px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  /* or 15px */

  /* qna타이틀내용 */

  color: #171717;
}

.product-order-additional-information-description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;

  margin-top: 10px;
  /* 프로필아이디 */
  color: #6b6b6b;
}

.product-order-additional-information-footer {
  height: 65px;
  padding: 10px 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product-order-additional-information-done-button {
  flex-grow: 1;
  color: #2667eb;
  background: #ffffff;
  /* 쨍한파랑 */

  border: 1px solid #2667eb;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height, or 21px */

  text-align: center;
}

.product-order-done-message-container {
  width: 100%;
  background: #eeeeee;

  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 130%;
  /* or 19px */

  text-align: center;

  /* qna타이틀내용 */

  color: #171717;
  padding: 10px 0px;
  margin-bottom: 10px;
}

.product-order-done-information-price {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 137.88%;
  /* or 23px */

  /* qna타이틀내용 */

  color: #171717;
  flex-grow: 1;
}

.product-order-additional-information-footer {
  height: 65px;
  padding: 10px 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product-order-additional-information-done-button {
  flex-grow: 1;
  background: #fff;
  color: #2667eb;
  border: 1px solid #2667eb;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height, or 21px */

  text-align: center;
}

.product-order-additional-information-upload-container {
  margin-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
}

.product-order-additional-information-upload-button-container {
  flex-grow: 1;
  height: 33px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
}

.product-order-additional-information-upload-button {
  height: 32px;
  width: 100%;
  --padding-start: 25px;
  --padding-end: 25px;
  margin: 0px;
  background: #ffffff;
  /* 시그니처컬러 */
  border: 1px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 16.1667px;

  color: #2d9cdb;
}

.product-order-additional-information-upload-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.product-order-additional-information-upload-button-icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
}

.product-order-additional-information-upload-button-text {
  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;

  color: #a1a0a5;
}

.product-order-additional-information-upload-viewer-container {
  width: 100%;
}
.product-order-additional-information-upload-viewer {
  margin-top: 15px;
  height: auto;
  width: 100%;
}
