.interest-select-popup-container {
  --width: 100%;
  /* --height: 240px; */
  --background: #0000;
  padding-left: 8px;
  padding-right: 8px;
}

.interest-select-popup-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.interest-select-popup-backdrop {
  flex-grow: 1;
  width: 100%;
}

.interest-select-container {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 17px;
  padding-right: 17px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.interest-select-icon {
  width: 36px;
  height: 36px;
  margin-bottom: 29px;
}

.interest-select-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.interest-select-title {
  flex-grow: 1;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.04em;
}

.interest-select-title-highlight {
  color: #2d9cdb;
}

.interest-select-body-container {
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;

  color: #6b6b6b;
}

.ion-color-interest-select-popup-close {
  --color: #ececec;
  --padding-start: 13px;
  --padding-end: 0px;
  --box-shadow: none;
  margin: 0px;
}

.interest-select-popup-close-icon {
  width: 20px;
  height: 20px;
}

.interest-select-description-highlight {
  font-weight: bold;
}
