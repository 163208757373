.register1-container {
  padding-top: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #dae8ef;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.register1-service-description {
  width: 220px;

  font-size: 12px;
  line-height: 17px;
  text-align: center;

  color: #6b6b6b;
}

.register1-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 5px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-radius: 13px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.register1-button-description {
  margin-top: 150px;
  margin-bottom: 15px;

  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;

  color: #2d9cdb;
}

.register1-button-description-highlight {
  font-weight: bold;
  font-size: 15px;
}

.register1-button {
  width: 100%;
  margin-top: 0px;
  height: 48px;
  --background: #2d9cdb;
  --border-radius: 24px;

  font-size: 17px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;

  color: #ffffff;
}

.register1-item-container {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  align-items: center;
}

.register1-item-name {
  min-width: 30px;
  margin-right: 20px;

  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #888888;
}

.register1-item-value-diabled {
  flex-grow: 1;

  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #a1a0a5;
}

.register1-item-input {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;

  margin-right: 10px;

  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #171717;

  border-bottom: 0.5px solid #a1a0a5;
}
