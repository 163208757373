.sales-product-viewer-content {
  width: 100%;
  height: calc(100% - 108px);
  background: #fff;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.sales-product-viewer-cardview-container {
  padding: 0px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #fff;
  margin-bottom: 10px;
}

.sales-product-viewer-cardview-square-container {
  width: 100%;
  /* position: relative; */
}

.sales-product-viewer-cardview-swipe {
  width: 100%;
  height: 100%;
  top: 0px;
}

.sales-product-viewer-cardview-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sales-product-viewer-index-container {
  position: absolute;
  bottom: 20px;
  left: 0px;
  width: 100%;
  z-index: 3;
}

.sales-product-viewer-page-selected {
  background: #fff8;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 0px 7px;
}

.sales-product-viewer-page {
  background: #bbb8;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: 0px 8px;
}

.sales-product-viewer-information-container {
  width: 100%;
  padding: 10px 16px;
  margin-bottom: 7px;
}

.sales-product-viewer-information-title {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 137.88%;
  /* or 23px */

  color: #000000;
  margin-bottom: 9px;
}

.sales-product-viewer-information-discount {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 18px */

  color: #000000;
}

.sales-product-viewer-information-discount-original {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 18px */

  text-decoration-line: line-through;

  /* 프로필날짜조회수 */

  color: #888888;
  margin-left: 8px;
}

.sales-product-viewer-information-price {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 137.88%;
  /* or 23px */

  color: #ae0003;
}

.sales-product-viewer-detail-title {
  padding: 10px 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  /* identical to box height, or 15px */

  color: #000000;
}

.sales-product-viewer-detail-container {
  width: 100%;
  max-height: 490px;
  padding: 0px 20px;
  overflow: hidden;
  position: relative;
}

.sales-product-viewer-detail-container-expanded {
  width: 100%;
  padding: 0px 20px;
}

.sales-product-viewer-detail-more-button-container {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9px 0px;
  background-color: #fffd;
  position: absolute;
  bottom: 0px;
}

.sales-product-viewer-detail-more-button {
  border: 1px solid #366afe;
  width: 250px;
  height: 40px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 137.88%;
  /* identical to box height, or 21px */

  text-align: center;
  background: #fff;
  color: #366afe;
}

.sales-product-viewer-footer {
  height: 65px;
  padding: 10px 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sales-product-viewer-share-button {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.sales-product-viewer-buy-button {
  flex-grow: 1;
  color: #fff;
  background: #2667eb;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height, or 21px */

  text-align: center;
}
