
.dynamic-checker-checked {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 2px solid #2D9CDB;
    color: #2D9CDB;
    font-size: 14px;
    padding-top: 4px;
    text-align: center;
}

.dynamic-checker-unchecked {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 2px solid #ececec;
    color: #ececec;
    font-size: 14px;
    padding-top: 4px;
    text-align: center;
}

.dynamic-checker-unchecking {
    width: 24px;
    height: 24px;
}

.dynamic-checker-warning {
    width: 24px;
    height: 24px;
    color: #f00;
    font-size: 14px;
    padding-top: 0px;
    text-align: center;
}