.ion-color-composer-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 53px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 53px;

  font-size: 16px;
  line-height: normal;
}

.composer-content {
  width: 100%;
  height: 100%;
  background: #fff;
}

.composer-container {
  width: 100%;
  height: calc(100% - 40px);
  /* display: flex;
    flex-direction: column; */
  padding-left: 17px;
  padding-right: 17px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.composer-reommends-container {
  position: absolute;
  width: 100%;
  max-width: 100%;
  bottom: 0px;
  left: 0px;
}

.composer-button {
  font-size: 16px;
  line-height: normal;
  color: #2d9cdb;
  font-weight: bold;
  padding-right: 10px;
}

.ion-color-composer-show-parent {
  background: #fafafa;
  /* 시그니처컬러 */

  border: 1px solid #2d9cdb;

  --box-shadow: none;

  color: #2d9cdb;
  --ion-color-base: #2d9cdb;
  margin-right: 20px !important;
}

.composer-show-parent {
  position: relative;

  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  letter-spacing: -0.02em;
  padding: 0px 10px;
}

.composer-show-parent-new {
  color: #fff;
  text-shadow: -1px -1px 0 #eb5757, 1px -1px 0 #eb5757, -1px 1px 0 #eb5757, 1px 1px 0 #eb5757;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.composer-show-parent-spinner {
  height: 26px;
  width: 26px;
}

.composer-button-disabled {
  font-size: 16px;
  line-height: normal;
  color: #c4c4c4;
  padding-right: 10px;
}

.composer-profile-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.composer-title-container {
  width: calc(100% - 14px);
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 0.5px solid #ececec;
}

.composer-title-icon {
  height: 17px;
  width: 17px;
  margin-right: 10px;
  margin-top: 6px;
}

.composer-title-input {
  z-index: 2;
  width: 100%;
  font-weight: 800;

  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.04em;
  color: #171717;
  --padding-start: 0px;
  --padding-end: 0px;
}

.composer-title-input::placeholder {
  color: #a1a0a5;
}
.composer-title-input::-webkit-input-placeholder {
  color: #a1a0a5;
}

.composer-title-input-container {
  padding: 14px 10px;
  flex-grow: 1;
  position: relative;
  align-items: center;
}

.composer-hidden {
  display: none;
}

.composer-title-placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-left: 8px;
  font-weight: 800;

  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.04em;

  color: #c0c0c0;
  text-align: left;
}

.composer-title-placeholder-sub {
  font-size: 15px;
}

.composer-body-container {
  position: relative;
  width: calc(100% - 14px);
  min-height: 200px;
  padding: 20px;
  /* margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 40px; */
}

.composer-content-placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  font-size: 14px;
  line-height: 22px;

  color: #ababab;
  padding: 20px;
}

.composer-body {
  position: relative;
  background: #0000;
  z-index: 2;
  height: auto;
  width: 100%;
  border: 1px;

  font-size: 14px;
  line-height: 22px;
  padding: 0px;
  color: #171717;
  outline-style: none;
}
.composer-images-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.composer-images-preview {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 12px;
}

.composer-files-container {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: calc(100% - 14px);
  margin-left: 7px;
  margin-right: 7px;
}

.ion-color-composer-footer-button {
  --ion-color-base: #fff;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --padding-start: 10px;
  --padding-end: 10px;
}

.composer-footer-container {
  height: 40px;
  width: 100%;
  background: #0000;
  /* position: fixed; */
  /* bottom: 0; */
  /* z-index: 100; */
  margin-top: 12px;
}

.composer-footer-container-onkeyboard {
  bottom: 220px;
}

.composer-footer-buttonbar-container.composer-footer-buttonbar-container-onkeyboard {
  border-radius: 13px 13px 13px 13px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
}

.composer-footer-buttonbar-container {
  margin-left: 16px;
  background-color: #f7f8f9;
  box-shadow: -2px -3px 5px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 10;
  height: 40px;
  padding: 0px 12px;
  display: inline-flex;
  gap: 8px;
  position: relative;
}

.composer-footer-button-file-container {
  overflow: hidden;
  display: inline-block;
  position: relative;
}

.composer-footer-button-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 40px;
  height: 36px;
}

.composer-footer-button-icon {
  width: 20px;
  height: 20px;
}

.composer-footer-ios {
  height: 400px;
}

.composer-anonymous-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.composer-anonymous-toggle {
  zoom: 0.6;
  --background-checked: #2d9cdb;
  --background: #a1a0a5;
}

.composer-anonymous-button {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #2d9cdb;
}

.composer-anonymous-button-disabled {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #a1a0a5;
}

.composer-subcategory-container {
  width: 100%;
  background: #f7f8f9;
  padding: 12px;
  margin-bottom: 20px;

  border: 1px solid #e4ebed;
  border-radius: 8px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
}

.composer-subcategory-invite-description-title {
  margin-bottom: 6px;
}

.composer-subcategory-invite-container {
  width: 100%;
  background: #fff;
  border: 1px solid #e4ebed;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 12px;
  align-items: flex-start;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
}

.composer-subcategory-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.04em;

  color: #000000;
  margin-right: 15px;
  margin-top: 3px;
  min-width: 56px;
}

.composer-subcategory-items-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
}

.composer-subcategory-item-toggle {
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #e4ebed;
  box-sizing: border-box;
  border-radius: 18px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3f4d5d;
  margin-bottom: 10px;
  margin-right: 10px;
}

.composer-subcategory-item-toggle-selected {
  background: #2d9cdb !important;
  color: #fff !important;
}

.composer-subcategory-done-button {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */

  /* 시그니처컬러 */

  color: #2d9cdb;
}

.composer-subcategory-body {
  flex-grow: 1;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 13px */

  /* Gray 2 */

  color: #4f4f4f;
  margin-top: 4px;
}

.composer-subcategory-modify-button {
  min-width: 32px;
  height: 21px;
  background: #2d9cdb;
  border-radius: 8px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  color: #fff;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
}
