.landing-container {
  width: 100%;
  height: 100%;
  max-width: 360px;
  max-height: min(100%, 720px);
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
}

.landing-button-container {
  height: 130px;
  min-height: 130px;
  background: #2d9cdb;
}

.landing-button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  color: #ffffff;
  margin-top: 25px;
  margin-bottom: 20px;
}

.landing-button-image {
  width: 130px;
  margin: 0px 20px;
}
