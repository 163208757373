.linkmeta-container {
  width: 100%;
  max-width: 480px;
  background: #f6f6f8;
  /* 하단바 비활성_New */

  border: 1px solid #afafaf;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkmeta-image {
  background: #f0f0f0;
  object-fit: cover;
  width: 90px;
  height: 90px;
}

.linkmeta-info-container {
  flex-grow: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.linkmeta-title {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.04em;
  margin-bottom: 6px;
  color: #171717;
}

.linkmeta-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 130%;
  /* or 14px */

  letter-spacing: -0.04em;

  /* qna타이틀내용 */

  color: #171717;
}

.linkmeta-youtube-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.linkmeta-youtube {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.linkmeta-link-icon-container {
  width: 15px;
  height: 15px;
  /* padding: 4.5px; */
  background: #fff;
  border: 1px solid #afafaf;
  border-radius: 3px;
  position: relative;
  margin-right: 6px;
}
.linkmeta-link-icon {
  width: 6px;
  height: 6px;
  object-fit: contain;
  position: absolute;
  left: 3px;
  top: 3px;
}

.linkmeta-url {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */

  /* 프로필아이디 */

  color: #6b6b6b;
}
