.compose-cardnews-top-button {
  min-width: 50px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 150%;
  text-align: center;
}

.compose-cardnews-top-button-other {
  /* 화이트 */
  border: 1px solid #2667eb;
  color: #2667eb;
  position: relative;
}

.compose-cardnews-top-button-done {
  /* 화이트 */
  color: #fff;
  background: #2667eb;
}

.compose-cardnews-card-button {
  min-width: 30px;
  width: 30px;
  border: 1px solid #2667eb;
  color: #2667eb;
  margin-left: 10px;
}

.compose-cardnews-thumbnail {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 10px;
}

.compose-cardnews-textarea {
  width: 100%;
  min-height: 50px;
}
