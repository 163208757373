.admin-product-manage-body-container {
  width: 100%;
  min-height: 300px;
  max-height: 600px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin-left: 7px;
  margin-right: 7px;
  padding-bottom: 10px;
}
/* 

.admin-product-manage-body-container {
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin-left: 7px;
    margin-right:7px;
    padding-bottom: 10px;
}

.admin-product-manage-sub-container {
    border: 0.5px solid #DDD;
}


.admin-product-manage-sub-input-container {
    flex-grow: 1;
    border: 0.5px solid #DDD;
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
    margin-left: 10px;
    --padding-start: 10px;
    --padding-end: 0px;
    --padding-top: 0px;
    --padding-bottom: 0px;
}

.admin-product-manage-sub-item-title{
    min-width: 50px;
}

.admin-product-manage-sub-image {
    width: 80px;
    height: 80px;
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
    border: 0.5px solid #DDD;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    object-fit: cover;
}

.admin-product-manage-sub-life {
    width: 30px;
    height: 180px;
    background: greenyellow;
}

.admin-product-manage-sub-life-deleted {
    width: 30px;
    height: 180px;
    background: lightsalmon;
}

.admin-product-manage-banner-image {
    flex-grow: 1;
    border: 0.5px solid #DDD;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: 80px;

} */
