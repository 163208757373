#app {
  width: 100%;
  height: 100%;
}

ion-app.ion-page {
  margin: auto;
  max-width: 600px;
  height: 100%;
}

ion-app.common-content {
  margin: auto;
  max-width: 600px;
  height: 100%;
}

ion-app.ion-page.admin {
  margin: auto;
  max-width: unset;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  ion-app.ion-page {
    width: 100%;
    height: 100%;
  }

  ion-app.common-content {
    width: 100%;
    height: 100%;
  }
}
.Toastify__toast-container {
}

.Toastify__toast {
  background: #2d9cdb !important;
}

.Toastify__toast-body {
  width: 100%;
  background: #2d9cdb !important;
}

.Toastify__toast-body > div {
  width: 100%;
}

.app-chat-noti-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  letter-spacing: -0.2px;

  /* 화이트 */
  color: #ffffff;
}

.app-chat-noti-profile {
  width: 38px;
  height: 38px;
  border-radius: 19px;
  margin-right: 8px;
}

.app-chat-noti-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.app-chat-noti-body {
}
