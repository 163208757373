.dynamic-checker-input-container {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  height: 54px;
  align-items: flex-start;
}

.dynamic-checker-input-title {
  min-width: 53px;
  margin-right: 10px;

  font-size: 15px;
  font-weight: bold;
  text-align: center;
  line-height: normal;
  letter-spacing: 0.1em;
  margin-top: 5px;
  word-wrap: normal;
  word-break: keep-all;

  color: #888888;
}

.dynamic-checker-input-title-warn {
  color: #fd6128;
}

.dynamic-checker-input-input-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 24px;
}

.dynamic-checker-input-input.dynamic-checker-input-input-warn {
  border-bottom: 0.5px solid #fd6128;
}

.dynamic-checker-input-input {
  width: calc(100% - 50px);
  /* flex-grow: 1; */
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;

  margin-right: 10px;

  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #171717;
  border: none;
  border-bottom: 0.5px solid #a1a0a5;
  outline-style: none;
  background-color: #fff;
}

.dynamic-checker-input-warning {
  padding-top: 3px;

  font-size: 10px;
  line-height: 14px;

  color: #fd6128;
}

.dynamic-checker-input-message {
  padding-top: 3px;

  font-size: 10px;
  line-height: 14px;

  color: #2d9cdb;
}
