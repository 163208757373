.register2-container {
  padding-top: 18px;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  background: #dae8ef;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.register2-service-description {
  width: 100%;
  text-align: center;

  font-size: 12px;
  line-height: 17px;
  text-align: center;

  color: #6b6b6b;
  margin-bottom: 5px;
}

.register2-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 5px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-radius: 13px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.register2-item-container {
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  align-items: center;
}

.register2-item-name {
  min-width: 30px;
  margin-right: 20px;

  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #888888;
}

.register2-item-value-diabled {
  flex-grow: 1;

  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #a1a0a5;
}

.register2-item-input {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;

  margin-right: 10px;

  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #171717;

  border-bottom: 0.5px solid #a1a0a5;
}

.register2-item-checked {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px solid #2d9cdb;
  color: #2d9cdb;
  font-size: 14px;
  padding-top: 4px;
  text-align: center;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
}

.register2-item-unchecked {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px solid #ececec;
  color: #ececec;
  font-size: 14px;
  padding-top: 4px;
  text-align: center;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
}

.register2-title-description {
  margin-top: 8px;

  font-size: 10px;
  line-height: normal;

  color: #2d9cdb;
}

.register2-title-description-highlight {
  margin-top: 15px;
  font-weight: bold;
}

.register2-work-type-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  margin-top: 10px;
  margin-bottom: 5px;
}

.register2-license-guide {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 10px;

  font-size: 13px;
  line-height: 20px;
  color: #fd6128;
}

.register2-work-type-selected {
  height: 32px;
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 2px;
  width: 64px;
  background: #2d9cdb;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  font-size: 11px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #f2f2f2;
}

.register2-work-type-unselected {
  height: 32px;
  padding-top: 10px;
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 2px;
  width: 64px;
  background: #fff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  font-size: 11px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #a1a0a5;
}

.register2-upload-container {
  margin-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
}

.register2-upload-button-container {
  flex-grow: 1;
  height: 33px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
}

.register2-upload-button {
  height: 32px;
  width: 100%;
  --padding-start: 25px;
  --padding-end: 25px;
  margin: 0px;
  background: #ffffff;
  /* 시그니처컬러 */
  border: 1px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 16.1667px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  color: #2d9cdb;
}

.register2-upload-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.register2-upload-button-icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
}

.register2-upload-button-text {
  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;

  color: #a1a0a5;
}

.register2-upload-viewer-container {
  width: 100%;
}
.register2-upload-viewer {
  margin-top: 15px;
  height: auto;
  width: 100%;
}
