.ytp-show-cards-title {
  visibility: hidden;
}

.video-play-player {
  width: 100%;
  margin-bottom: -3px;
}

.video-play-icon {
  width: 80px;
  height: 80px;
  color: #fff;
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  filter: drop-shadow(0px 0px 10px #0008);
}

.video-player-toggle-selected {
  background: #2d9cdb !important;
  color: #f2f2f2 !important;
}

.video-player-toggle {
  height: 20px;
  padding: 5px 10px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 11px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  background: #fff;
  color: #a1a0a5;
}

.video-play-toolbar {
  background: #dae8ef;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 25px;
}

.video-play-toolbar-resolution {
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 14px;

  letter-spacing: -0.04em;

  /* 프로필아이디 */
  padding-left: 5px;
  padding-right: 5px;
  color: #6b6b6b;
}

.video-play-toolbar-resolution-selected {
  color: #2d9cdb;
}

.video-play-toolbar-resolution-toggle {
  zoom: 0.4;
  --background-checked: #2d9cdb;
  --background: #a1a0a5;
}

.video-play-toolbar-speed-down-button {
  color: #0000;
  width: 12px;
  height: 12px;
  padding: 0px 20px;
}

.video-play-toolbar-speed-up-button {
  color: #0000;
  width: 12px;
  height: 12px;
  padding: 0px 20px;
}

.video-play-toolbar-speed {
  color: #2d9cdb;

  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 14px;

  letter-spacing: -0.04em;

  /* 프로필아이디 */
  padding-left: 5px;
  padding-right: 5px;
  margin-left: -20px;
  margin-right: -20px;
  min-width: 45px;
  text-align: center;
}
