.search-address-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 13px; */

  /* padding-bottom: 13px; */
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.search-address-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 13px;
}

.search-address-title {
  margin-left: 12px;

  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  font-weight: bold;
  text-align: left;
  letter-spacing: 0.05em;

  color: #171717;
}

.search-address-input-description {
  /* margin-top: 16px; */
  margin-left: 12px;

  font-size: 18px;
  line-height: 33px;

  color: #6b6b6b;
}

.register-search-input-container {
  display: flex;
  flex-direction: row;

  height: 40px;
  background: #ffffff;
  /* 하단바비활성화 */
  border: 0.5px solid #a1a0a5;
  box-sizing: border-box;
  border-radius: 20px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 15px;
  margin-bottom: 16px;
  padding-left: 14px;
}

.search-address-type-container {
  width: 100%;
  height: calc(100% - 59px);
}

.search-address-input {
  --padding-start: 0px;
  --padding-end: 0px;
  flex-grow: 1;
  --placeholder-color: #a1a0a5;
  --placeholder-opacity: 1;

  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  color: #171717;
}

.search-address-list-container {
  width: 100%;
  height: calc(100% - 100px);
  margin-bottom: 15px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.search-address-list {
}

.search-address-item-container {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --background: rgba(244, 244, 244, 0.5);
  margin-bottom: 8px;
  width: 100%;
}

.search-address-item-inner {
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 16px;
  margin-right: 16px;
}

.search-address-item-name {
  font-size: 15px;
  line-height: normal;
  font-weight: bold;

  color: #6b6b6b;
  margin-bottom: 6px;
}

.search-address-item-address {
  font-size: 14px;
  line-height: normal;
  margin-bottom: 5px;
  color: #6b6b6b;
}

.search-address-item-phone-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-address-item-phone-title {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  height: 15.3px;

  font-size: 9px;
  line-height: normal;
  margin-right: 3px;
  border: 0.5px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 7.65144px;
  color: #888888;
}

.search-address-item-phone {
  font-size: 12px;
  line-height: normal;

  color: #6b6b6b;
}

.search-address-segment-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
}

.ion-color-search-address-segment {
  width: 160px;
  margin-left: 8px;
  margin-right: 8px;
  height: 26px;
  border-radius: 13px 13px 13px 13px;
  background-color: #a1a0a5;
  justify-items: left;
  --ion-color-base: #0000;
  /* margin-bottom: -16px; */
}

.search-address-segment-button {
  height: 26px !important;
  min-height: 26px;
  min-width: 50px;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #ffffff !important;
  border-radius: 13px 13px 13px 13px;
  background: #a1a0a5 !important;
}

.search-address-segment-button.segment-button-checked {
  background: #2d9cdb !important;

  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
}

.search-address-segment-button-text {
  width: 100%;
  height: 41px;
  padding-top: 7px;
}

.search-address-segment-title {
  position: relative;
  border-radius: 13px 13px 13px 13px;
  background: #fff;
  width: 100px;
  margin-left: 8px;
  margin-right: 8px;
  height: 26px;
  margin-top: -13px;
  padding-top: 14px;
  padding-left: 16px;

  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-transform: capitalize;

  color: #fd6128;
}

.search-address-segment-title-highlight {
  font-weight: bold;
}

.search-address-postal-container {
  /* display:none; */

  width: 100%;
  height: calc(100% - 130px);
  /* margin:5px 0; */

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  position: relative;
}

#postal-search {
  position: relative;
  width: 100%;
  border: none;
  /* height: 100%; */
}
.search-address-manual-item-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: flex-start;
}

.search-address-manual-item-name {
  min-width: 30px;
  margin-top: 3px;
  margin-right: 10px;

  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #888888;
}

.search-address-manual-item-input {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;

  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;
  flex-grow: 1;

  color: #171717;

  border-bottom: 0.5px solid #a1a0a5;
}

.ion-color-serach-address-manual-done-button {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 10px;
  height: 26px;
  --ion-color-base: #2d9cdb;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  --border-radius: 13px;

  font-size: 17px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;

  color: #ffffff;
}
.search-address-close {
  --color: #939393;
  height: 17px;
  --padding-start: 13px;
  --padding-end: 13px;
  margin: 0px;
}
.search-address-close-icon {
  width: 14px;
  height: 14px;
}
