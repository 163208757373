.myprofile-change-content {
  width: 100%;
  --background: #dae8ef;
}

.myprofile-change-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  align-items: center;
}

.myprofile-change-header {
  height: 43px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.ion-color-myprofile-change-toolbar-button-enable {
  --ion-color-base: #2d9cdb !important;
  color: #2d9cdb !important;
}

.ion-color-myprofile-change-toolbar-button {
  --ion-color-base: #a1a0a5 !important;
  color: #a1a0a5 !important;
}

.myprofile-change-title {
  font-size: 20px;
  font-weight: bold;
  color: #171717;
}

.myprofile-change-item-description {
  margin-top: 4px;
  margin-bottom: 8px;

  font-size: 10px;
  line-height: normal;

  color: #2d9cdb;
}

.myprofile-change-item-highlight {
  font-weight: bold;
}

.myprofile-change-item-textarea {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;

  color: #6b6b6b;
}

.myprofile-change-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 13px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-radius: 13px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.myprofile-change-item-container {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 8px;
  align-items: flex-start;
}

.myprofile-change-item-name {
  min-width: 39px !important;
  margin-top: 3px !important;
  margin-right: 10px !important;

  font-weight: normal !important;
  font-size: 10px !important;
  line-height: normal !important;
  letter-spacing: 0.1em !important;
  text-align: left !important;

  color: #888888 !important;
  white-space: nowrap;
}

.myprofile-change-item-input {
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --padding-top: 0px !important;
  --padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  /* margin-left:10px !important; */
  margin-right: 10px !important;

  font-size: 16px !important;
  line-height: bold !important;
  letter-spacing: 0.1em !important;
  width: calc(100% - 30px) !important;

  color: #171717 !important;
  border: none !important;
  border-bottom: 0.5px solid #a1a0a5 !important;
  outline-style: none !important;
}

.ion-color-myprofile-change-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 43px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 43px;

  font-size: 16px;
  line-height: normal;

  color: #a1a0a5;
}

.ion-color-myprofile-chat-button {
    --border-radius: 0px;
    --border-width: 0px;
    --border-style: solid;
    --border-color: #0000;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    margin: 0px;
    margin-inline: 0px;
    --box-shadow: none;
    border: 1px solid #6B6B6B;
    border-radius: 1000px;
    white-space: nowrap;
    height: 24px;
    padding: 0px 16px;
    margin-right: 10px;
    color: #6B6B6B;
}

.myprofile-change-work-type-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 28px;
}

.myprofile-change-work-type-selected {
  height: 32px;
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 2px;
  width: 64px;
  background: #2d9cdb;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  font-size: 11px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #f2f2f2;
}

.myprofile-change-work-type-unselected {
  height: 32px;
  padding-top: 10px;
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 2px;
  width: 64px;
  background: #fff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  font-size: 11px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #a1a0a5;
}
