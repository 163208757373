.progress-popup-container {
  --width: 100%;
  --height: 200px;
  --background: #0000;
  padding-left: 8px;
  padding-right: 8px;
  --box-shadow: none;
}

.progress-container {
  width: 100%;
  height: 100%;
  background: #0000;
  box-shadow: none;
  border-radius: 13px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 17px;
  padding-right: 17px;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-spinner {
  width: 100px;
  height: 100px;
  margin-right: 50px;
  margin-left: 50px;
}

.progress-label {
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;

  color: #a1a0a5;
}
