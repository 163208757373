.ion-color-notifications-toolbar {
    --ion-color-base: #fff;
    --ion-color-base-rgb: 255, 255, 255;
    --ion-color-shade: #c4c4c4;
    --ion-color-contrast: #c4c4c4;
    --ion-color-tint: #39393980;

    --min-height: 43px;
    --padding-top: 0px;
    --padding-botoom: 0px;
    --padding-left: 0px;
    --padding-right: 0px;

    height: 43px;
}

.notifications-header {
    height: 43px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.notifications-title{
    color: #393939;
}
